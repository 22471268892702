import { useEffect, useMemo, useState } from 'react'
import SiteSelector from '../../Components/SiteSelector'
import Table from '@components/Table/Table'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import DropdownField from '@components/Form/DropdownField'
import EditModal from '@components/EditModal'
import { Link } from 'react-router-dom'
import { STATUS_CLASSES, STATUS_CONFIG } from '@utilities/Constants'

import './Building.scss'
import EventDetailsModal from '../../Components/Building/EventDetailsModal'

const Building = () => {
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [cancellationReason, setCancellationReason] = useState('')

  const cancelEventLoading = useStoreState((state) => state.control.cancelEventLoading)
  const selectedBuilding = useStoreState((state) => state.building.selected)
  const selectedSite = useStoreState((state) => state.site.selected)
  const eventOptions = useStoreState((state) => state.control.eventOptions)
  const buildingOptions = useStoreState((state) => state.building.buildingOptions)
  const getEvents = useStoreActions((action) => action.control.getEvents)
  const buildingSelector = useStoreActions((action) => action.building.setSelected)
  const cancelEvent = useStoreActions((action) => action.control.cancelEvent)

  const formattedBuildingOptions = useMemo(
    () => buildingOptions?.map((x) => ({ value: x.id, label: x.name })) ?? [],
    [buildingOptions]
  )

  const [showModal, setShowModal] = useState(false)

  function formatDate(dateString) {
    const date = new Date(dateString)

    if (isNaN(date.getTime())) {
      return ''
    }

    const options = {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    }

    return new Intl.DateTimeFormat('en-US', options).format(date)
  }

  const cancelEventHandler = async () => {
    await cancelEvent({ id: selectedEvent.id, reason: cancellationReason })
    // reload events
    await getEvents({
      ...(selectedSite?.value && { sid: [selectedSite?.value] }),
      ...(selectedBuilding?.value && { bid: [selectedBuilding?.value] }),
    })
    setShowModal(false)
    setCancellationReason('')
  }

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Event ID',
        accessor: 'id',
      },
      {
        Header: 'Creator',
        accessor: 'createdBy',
      },
      {
        Header: 'Start',
        accessor: 'start',
        Cell: ({ value }) => {
          return <span>{formatDate(value ?? '')}</span>
        },
        disableFilters: true,
      },
      {
        Header: 'End',
        accessor: 'end',
        Cell: ({ value }) => {
          return <span>{formatDate(value ?? '')}</span>
        },
        disableFilters: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => {
          return <span className={`status-badge ${STATUS_CLASSES[value] || ''}`}>{value}</span>
        },
      },
      {
        Header: '',
        accessor: 'actions',
        Cell: ({ row }) =>
          STATUS_CONFIG[row.original.status]?.allowActions ? (
            <button
              className="icon"
              onClick={() => {
                setSelectedEvent(row.original)
                setShowModal(true)
              }}
            >
              <FontAwesomeIcon icon={faTrashCan} />
            </button>
          ) : null,
        disableFilters: true,
      },
      {
        Header: '',
        accessor: 'details',
        Cell: ({ row }) => <EventDetailsModal siteName={selectedSite.label} event={row.original} />,
        disableFilters: true,
      },
    ],
    []
  )

  useEffect(() => {
    // get event even without params
    getEvents({
      ...(selectedSite?.value && { sid: [selectedSite?.value] }),
      ...(selectedBuilding?.value && { bid: [selectedBuilding?.value] }),
    })
  }, [selectedSite, selectedBuilding])

  const handleModalClose = () => {
    setShowModal(false)
    setSelectedEvent(null)
    setCancellationReason('') // Reset the reason when closing the modal
  }

  // Initial sorting
  const initialSort = [{ id: 'start', desc: true }]

  return (
    <div className="App-submodule" style={{ minHeight: '300px' }}>
      <div className="App-submodule-header">
        <div style={{ display: 'flex' }}>
          <SiteSelector className={'events-dropdowns form-field'} />
          <DropdownField
            label="Building"
            name="selectedBuilding"
            value={selectedBuilding}
            onChange={buildingSelector}
            options={formattedBuildingOptions}
            className="events-dropdowns building-dropdown form-field"
          />
        </div>

        <Link to="/event/scheduler" className="button scheduler-button">
          <FontAwesomeIcon icon={faCirclePlus} width={15} height={15} className="schedule-event-icon" />
          <span>Schedule Event</span>
        </Link>
      </div>
      <div className="row" style={{ alignItems: 'flex-start' }}>
        <div style={{ flexGrow: 1 }}>
          <Table
            singleSelect
            columns={tableColumns}
            data={eventOptions}
            className="event-table"
            disableSelection={true}
            initialSort={initialSort}
          />
        </div>
      </div>
      <EditModal
        title="Cancel Event"
        show={showModal}
        autoFocus={false}
        loading={cancelEventLoading}
        onSubmit={cancelEventHandler}
        onHide={handleModalClose}
        cancelText="Go Back"
        submitText="Cancel Event"
        submitIcon={faTrashCan}
        showOkButton={false}
        className="building-modal modal"
        submitDisabled={!cancellationReason.trim()}
      >
        <div className="wrapper-content">
          <span style={{ display: 'flex' }}>
            Are you sure you want to cancel the event at this building? Canceling the event will notify all users with
            access to this building via email or SMS.
          </span>
          <span style={{ display: 'flex', margin: '20px 0' }}>You will not be able to undo this.</span>
          <div>
            <div>
              <span className="title">Event:</span> <span>Event {selectedEvent?.id}</span>
            </div>
            <div>
              <span className="title">Building:</span> <span>{selectedEvent?.buildingName}</span>
            </div>
          </div>
          <div className="form-field">
            <div className="title">
              <span>Cancellation Reason*</span>
            </div>
            <textarea
              cols={3}
              rows={3}
              style={{ resize: 'none' }}
              value={cancellationReason}
              onChange={(e) => setCancellationReason(e.target.value)}
            />
          </div>
        </div>
      </EditModal>
    </div>
  )
}

export default Building
